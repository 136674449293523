import { CountryCode, isCountryCode, isZoneCode, ZoneCode } from "./countries";
import { Country, Regions, Zone } from "./types";

export class RegionFilter {
    private regions: Regions;

    /** Set of all included countries. */
    private countries: Set<CountryCode>;

    /** Precomputed set of fully included zones. */
    private zones: Set<ZoneCode>;

    constructor(regions: Regions, countries: Set<CountryCode> = new Set()) {
        this.regions = regions;
        this.countries = countries;

        const zones = new Set<ZoneCode>();
        for (const zone of regions.zoneMap.values()) {
            if (zone.countries.every(c => countries.has(c.code))) {
                zones.add(zone.code);
            }
        }
        this.zones = zones;
    }

    isEmpty(): boolean {
        return this.countries.size === 0;
    }

    includesCountryCode(cc: CountryCode): boolean {
        return this.countries.has(cc);
    }

    includesCountry(country: Country): boolean {
        return this.includesCountryCode(country.code);
    }

    includesZoneCode(zone: ZoneCode): boolean {
        return this.zones.has(zone);
    }

    includesZone(zone: Zone): boolean {
        return this.includesZoneCode(zone.code);
    }

    includesAll(): boolean {
        const n = this.regions.zones.reduce((n, z) => n + z.countries.length, 0);
        return this.countries.size === n;
    }

    updateCountry(country: Country, include: boolean): RegionFilter {
        const countries = new Set(this.countries);
        if (include) {
            countries.add(country.code);
        } else {
            countries.delete(country.code);
        }
        return new RegionFilter(this.regions, countries);
    }

    updateZone(zone: Zone, include: boolean): RegionFilter {
        const countries = new Set(this.countries);
        for (const country of zone.countries ?? []) {
            if (include) {
                countries.add(country.code);
            } else {
                countries.delete(country.code);
            }
        }
        return new RegionFilter(this.regions, countries);
    }

    updateAll(include: boolean): RegionFilter {
        return new RegionFilter(this.regions, new Set(include ? this.regions.countries.map(c => c.code) : []));
    }

    updateFromEncodedString(s: string | null): RegionFilter {
        if (!s) return this.updateAll(true);
        
        let filter: RegionFilter = this;

        for (const token of s.split(',')) {
            const zone = isZoneCode(token) ? this.regions.zoneMap.get(token) : undefined;
            if (zone) {
                filter = filter.updateZone(zone, true);
                continue;
            }

            const country = isCountryCode(token) ? this.regions.countryMap.get(token) : undefined;
            if (country) {
                filter = filter.updateCountry(country, true);
                continue;
            }
        }

        return filter;
    }

    toEncodedString(): string | null {
        const compacted = this.compact();
        if (!compacted) return null;

        const tokens: string[] = [];
        for (const zone of compacted.zones) {
            tokens.push(zone.code);
        }
        for (const country of compacted.countries) {
            tokens.push(country.code);
        }
        return tokens.join(',');
    }

    compact(): null | { zones: Zone[], countries: Country[] } {
        if (this.includesAll()) return null;

        const zones = [];
        const countries = [];
        for (const zone of this.regions.zones) {
            if (this.includesZone(zone)) {
                zones.push(zone);
                continue;
            } 
            for (const country of zone.countries) {
                if (this.includesCountry(country)) {
                    countries.push(country);
                }
            }
        }
        return { zones, countries };
    }

    getOutofZoneCountries(): Set<CountryCode> {
        const outOfZoneCountries = new Set(this.countries);
        for (const zone of this.regions.zones) {
            if (this.includesZone(zone)) {
                for (const country of zone.countries) {
                    outOfZoneCountries.delete(country.code);
                }
            }
        }
        return outOfZoneCountries;
    }

    getFullyIncludedZones(): Set<ZoneCode> {
        return this.zones;
    }

    getSingleCountry(): Country | null {
        if (this.countries.size !== 1) return null;
        const code = Array.from(this.countries)[0];
        return this.regions.countryMap.get(code) ?? null;
    }

    getSingleZone(): Zone | null {
        if (this.zones.size !== 1) return null;
        if (this.getOutofZoneCountries().size > 0) return null;
        const code = Array.from(this.zones)[0];
        return this.regions.zoneMap.get(code) ?? null;
    }

    getCountriesCount(): number {
        return this.countries.size;
    }
};

export const emptyRegionFilter = new RegionFilter({ countries: [], zones: [], zoneMap: new Map(), countryMap: new Map() });
