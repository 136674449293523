import { useContext, useState } from 'react';

import { SettingsContext } from './settings';
import { DataContext } from './data';
import { Country, Zone } from './types';
import { Flag } from './common';

export const CountryTree = () => {
    const { settings: { regionFilter }, setSettings } = useContext(SettingsContext);
    const { regions } = useContext(DataContext);

    return <section className="region-tree">
        <RegionCheckBox
            id="all"
            checked={regionFilter.includesAll()}
            onToggle={e => setSettings({ type: 'set-include-world', include: e.target.checked })}
            onSetOnly={() => setSettings({ type: 'set-include-world', include: true })}>
            All countries
        </RegionCheckBox>

        <ul>
            {regions.zones.map(z => (
                <ZoneNode key={z.code} zone={z} />
            ))}
        </ul>
    </section>;
};

const ZoneNode = ({ zone }: { zone: Zone }) => {
    const { settings: { regionFilter }, setSettings } = useContext(SettingsContext);

    const [expanded, setExpanded] = useState(
        zone.countries.some(c => regionFilter.includesCountry(c))
        && zone.countries.some(c => !regionFilter.includesCountry(c)));

    return (
        <li>
            <RegionCheckBox
                id={zone.code}
                checked={regionFilter.includesZone(zone)}
                onToggle={e => setSettings({ type: 'set-include-zone', zone, include: e.target.checked })}
                onSetOnly={() => setSettings({ type: 'focus-zone', zone })}>
                {zone.name}
            </RegionCheckBox>

            <button onClick={() => setExpanded(!expanded)} tabIndex={0}>
                {expanded ? '\u25be' : '\u25b8'}
            </button>

            {expanded && (
                <ul>
                    {zone.countries.map(c => (
                        <CountryNode key={c.code} country={c} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const CountryNode = ({ country }: { country: Country }) => {
    const { settings: { regionFilter }, setSettings } = useContext(SettingsContext);

    return <>
        <li>
            <RegionCheckBox
                id={country.code}
                checked={regionFilter.includesCountry(country)}
                onToggle={e => setSettings({ type: 'set-include-country', country, include: e.target.checked })}
                onSetOnly={() => setSettings({ type: 'focus-country', country })}>
                {country.code != 'unknown' && <>
                    <Flag country={country} />
                    {" "}
                </>}
                {country.name}
            </RegionCheckBox>
        </li>
    </>;
};

const RegionCheckBox = ({ id, checked, onToggle, onSetOnly, children }: {
    id: string,
    checked: boolean,
    onToggle: React.ChangeEventHandler<HTMLInputElement>,
    onSetOnly: () => void,
    children: React.ReactNode,
}) => <>
        <input type="checkbox" value={id} onChange={onToggle} checked={checked} />
        <button onClick={onSetOnly}>
            {children}
        </button>
    </>;
