import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { SettingsContext } from "./settings";
import { thisSunday } from "./common";

export type ScrollTarget = null | { target: 'sunday', sunday: Date };

export const ScrollContext = createContext<{
    scrollToSunday: (sunday: Date) => void,
    scrollTarget: ScrollTarget,
    setScrollTarget: Dispatch<SetStateAction<ScrollTarget>>,
}>(undefined!);

export const ScrollContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { setSettings } = useContext(SettingsContext);
    const [scrollTarget, setScrollTarget] = useState<ScrollTarget>({ target: 'sunday', sunday: thisSunday });

    const context = {
        scrollTarget, setScrollTarget,
        scrollToSunday: (sunday: Date) => {
            setSettings({ type: 'set-year', year: sunday.getFullYear() });
            setScrollTarget({ target: 'sunday', sunday });
        },
    };

    return <ScrollContext.Provider value={context}>{children}</ScrollContext.Provider>;
};
