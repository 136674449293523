import { createContext, Dispatch, useContext, useEffect, useReducer } from 'react';
import { useQueryState } from 'nuqs';

import { Country, Event, Zone } from './types';
import { RegionFilter, emptyRegionFilter } from './region-filter';
import { thisYear } from './common';
import { DataContext } from './data';

export type EmptyWeekendsMode = 'hide' | 'show' | 'collapse';

export type Settings = {
    year: number | null;
    onlyWithPeople: null | Set<string>;
    onlyWSDC: boolean;
    onlyHotel: boolean;
    onlyVideos: boolean;
    onlyResults: boolean;
    hideCancelled: boolean;
    regionFilter: RegionFilter;
    emptyWeekends: EmptyWeekendsMode;
}

export type SetSettings = (update: ((settings: Settings) => Settings)) => void;

const defaultSettings: Settings = {
    year: null,
    onlyWithPeople: null,
    onlyWSDC: false,
    onlyHotel: false,
    onlyVideos: false,
    onlyResults: false,
    hideCancelled: false,
    regionFilter: emptyRegionFilter,
    emptyWeekends: 'collapse',
};

export const SettingsContext = createContext<{
    settings: Settings,
    setSettings: Dispatch<SettingsAction>,
}>({
    settings: defaultSettings,
    setSettings: () => { },
});

export const SettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const data = useContext(DataContext);

    const [regionFilterParam, setRegionFilterParam] = useQueryState('regions');

    const [settings, setSettings] = useReducer(settingsReducer, {
        year: thisYear,
        onlyWithPeople: null,
        onlyWSDC: false,
        onlyHotel: false,
        onlyVideos: false,
        onlyResults: false,
        hideCancelled: false,
        regionFilter: new RegionFilter(data.regions).updateFromEncodedString(regionFilterParam),
        emptyWeekends: 'collapse',
    });

    useEffect(() => {
        setRegionFilterParam(settings.regionFilter.toEncodedString());
    }, [settings.regionFilter]);

    return <SettingsContext.Provider value={{ settings, setSettings }}>{children}</SettingsContext.Provider>;
};

export function showEvent(s: Settings, e: Event): boolean {
    if (!s.regionFilter.includesCountry(e.country)) {
        return false;
    }
    if (s.onlyWSDC && !e.wsdc) {
        return false;
    }
    if (s.onlyHotel && !e.hotel) {
        return false;
    }
    if (s.onlyVideos && !e.hasVideos) {
        return false;
    }
    if (s.onlyResults && !e.hasResults) {
        return false;
    }
    if (s.hideCancelled && e.cancelled) {
        return false;
    }
    if (s.onlyWithPeople !== null && !e.people.some((p) => s.onlyWithPeople!.has(p))) {
        return false;
    }
    return true;
}

export function filterEvents(s: Settings, events: Event[]): Event[] {
    return events.filter((e) => showEvent(s, e));
}

export type SettingsAction =
    | { type: 'set-year', year: number | null }
    | { type: 'set-only-wsdc', value: boolean }
    | { type: 'set-only-with-hotel', value: boolean }
    | { type: 'set-only-with-videos', value: boolean }
    | { type: 'set-only-with-results', value: boolean }
    | { type: 'set-hide-cancelled', value: boolean }
    | { type: 'add-staff', name: string }
    | { type: 'remove-staff', name: string }
    // TODO: Split set-include into separate actions for include and exclude
    // TODO: Invert revertToWorldIfEmpty to be a "force" false-by-default flag
    | { type: 'set-include-country', country: Country, include: boolean, revertToWorldIfEmpty?: boolean }
    | { type: 'set-include-zone', zone: Zone, include: boolean, revertToWorldIfEmpty?: boolean }
    | { type: 'set-include-world', include: boolean }
    | { type: 'focus-country', country: Country }
    | { type: 'focus-zone', zone: Zone }
    | { type: 'clear-filters' }
    ;

export function settingsReducer(settings: Settings, action: SettingsAction): Settings {
    if (action.type === 'set-year') {
        return { ...settings, year: action.year };
    }

    if (action.type === 'set-only-wsdc') {
        return { ...settings, onlyWSDC: action.value };
    }

    if (action.type === 'set-only-with-hotel') {
        return { ...settings, onlyHotel: action.value };
    }

    if (action.type === 'set-only-with-videos') {
        return { ...settings, onlyVideos: action.value };
    }

    if (action.type === 'set-only-with-results') {
        return { ...settings, onlyResults: action.value };
    }

    if (action.type === 'set-hide-cancelled') {
        return { ...settings, hideCancelled: action.value };
    }

    if (action.type === 'add-staff') {
        return { ...settings, onlyWithPeople: new Set([...(settings.onlyWithPeople ?? []), action.name]) };
    }

    if (action.type === 'remove-staff') {
        const newSet = new Set(settings.onlyWithPeople);
        newSet.delete(action.name);
        return { ...settings, onlyWithPeople: newSet.size === 0 ? null : newSet };
    }

    const { regionFilter } = settings;

    if (action.type === 'set-include-country') {
        let newRegionFilter = regionFilter.updateCountry(action.country, action.include);
        if (action.revertToWorldIfEmpty && newRegionFilter.isEmpty()) {
            newRegionFilter = newRegionFilter.updateAll(true);
        }
        return { ...settings, regionFilter: newRegionFilter };
    }

    if (action.type === 'set-include-zone') {
        let newRegionFilter = regionFilter.updateZone(action.zone, action.include);
        if (action.revertToWorldIfEmpty && newRegionFilter.isEmpty()) {
            newRegionFilter = newRegionFilter.updateAll(true);
        }
        return { ...settings, regionFilter: newRegionFilter };
    }

    if (action.type === 'set-include-world') {
        return { ...settings, regionFilter: regionFilter.updateAll(action.include) }
    }

    if (action.type === 'focus-country') {
        return { ...settings, regionFilter: regionFilter.updateAll(false).updateCountry(action.country, true) };
    }

    if (action.type === 'focus-zone') {
        return { ...settings, regionFilter: regionFilter.updateAll(false).updateZone(action.zone, true) };
    }

    if (action.type === 'clear-filters') {
        return {
            ...settings,
            onlyWithPeople: null,
            onlyWSDC: false,
            onlyHotel: false,
            onlyVideos: false,
            onlyResults: false,
            hideCancelled: false,
            regionFilter: regionFilter.updateAll(true),
        };
    }

    throw Error(`Unknown action type: ${(action as any).type}`);
}
