
export const ZONES = {
  'other': { name: 'Other / Unknown' },
  'eme': { name: 'Europe and neighbours' },
  'apac': { name: 'Asia, Australia and Pacific' },
  'northam': { name: 'North America' },
  'southam': { name: 'South America' },
  'africa': { name: 'Africa' },
  'antarctica': { name: 'Antarctica' },
} as const;

export type ZoneCode = keyof typeof ZONES;

export const COUNTRIES = {
  "ad": { name: "Andorra", zoneCode: "eme" },
  "ae": { name: "United Arab Emirates", zoneCode: "apac" },
  "af": { name: "Afghanistan", zoneCode: "apac" },
  "ag": { name: "Antigua and Barbuda", zoneCode: "northam" },
  "ai": { name: "Anguilla", zoneCode: "northam" },
  "al": { name: "Albania", zoneCode: "eme" },
  "am": { name: "Armenia", zoneCode: "apac" },
  "ao": { name: "Angola", zoneCode: "africa" },
  "ap": { name: "Asia/Pacific Region", zoneCode: "apac" },
  "aq": { name: "Antarctica", zoneCode: "antarctica" },
  "ar": { name: "Argentina", zoneCode: "southam" },
  "as": { name: "American Samoa", zoneCode: "apac" },
  "at": { name: "Austria", zoneCode: "eme" },
  "au": { name: "Australia", zoneCode: "apac" },
  "aw": { name: "Aruba", zoneCode: "northam" },
  "ax": { name: "Aland Islands", zoneCode: "eme" },
  "az": { name: "Azerbaijan", zoneCode: "apac" },
  "ba": { name: "Bosnia and Herzegovina", zoneCode: "eme" },
  "bb": { name: "Barbados", zoneCode: "northam" },
  "bd": { name: "Bangladesh", zoneCode: "apac" },
  "be": { name: "Belgium", zoneCode: "eme" },
  "bf": { name: "Burkina Faso", zoneCode: "africa" },
  "bg": { name: "Bulgaria", zoneCode: "eme" },
  "bh": { name: "Bahrain", zoneCode: "apac" },
  "bi": { name: "Burundi", zoneCode: "africa" },
  "bj": { name: "Benin", zoneCode: "africa" },
  "bl": { name: "Saint Bartelemey", zoneCode: "northam" },
  "bm": { name: "Bermuda", zoneCode: "northam" },
  "bn": { name: "Brunei Darussalam", zoneCode: "apac" },
  "bo": { name: "Bolivia", zoneCode: "southam" },
  "bq": { name: "Bonaire", zoneCode: "southam" },
  "br": { name: "Brazil", zoneCode: "southam" },
  "bs": { name: "Bahamas", zoneCode: "northam" },
  "bt": { name: "Bhutan", zoneCode: "apac" },
  "bv": { name: "Bouvet Island", zoneCode: "antarctica" },
  "bw": { name: "Botswana", zoneCode: "africa" },
  "by": { name: "Belarus", zoneCode: "eme" },
  "bz": { name: "Belize", zoneCode: "northam" },
  "ca": { name: "Canada", zoneCode: "northam" },
  "cc": { name: "Cocos (Keeling) Islands", zoneCode: "apac" },
  "cd": { name: "Congo", zoneCode: "africa" },
  "cf": { name: "Central African Republic", zoneCode: "africa" },
  "cg": { name: "Congo", zoneCode: "africa" },
  "ch": { name: "Switzerland", zoneCode: "eme" },
  "ci": { name: "Cote d'Ivoire", zoneCode: "africa" },
  "ck": { name: "Cook Islands", zoneCode: "apac" },
  "cl": { name: "Chile", zoneCode: "southam" },
  "cm": { name: "Cameroon", zoneCode: "africa" },
  "cn": { name: "China", zoneCode: "apac" },
  "co": { name: "Colombia", zoneCode: "southam" },
  "cr": { name: "Costa Rica", zoneCode: "northam" },
  "cu": { name: "Cuba", zoneCode: "northam" },
  "cv": { name: "Cape Verde", zoneCode: "africa" },
  "cw": { name: "Curacao", zoneCode: "southam" },
  "cx": { name: "Christmas Island", zoneCode: "apac" },
  "cy": { name: "Cyprus", zoneCode: "apac" },
  "cz": { name: "Czech Republic", zoneCode: "eme" },
  "de": { name: "Germany", zoneCode: "eme" },
  "dj": { name: "Djibouti", zoneCode: "africa" },
  "dk": { name: "Denmark", zoneCode: "eme" },
  "dm": { name: "Dominica", zoneCode: "northam" },
  "do": { name: "Dominican Republic", zoneCode: "northam" },
  "dz": { name: "Algeria", zoneCode: "africa" },
  "ec": { name: "Ecuador", zoneCode: "southam" },
  "ee": { name: "Estonia", zoneCode: "eme" },
  "eg": { name: "Egypt", zoneCode: "africa" },
  "eh": { name: "Western Sahara", zoneCode: "africa" },
  "er": { name: "Eritrea", zoneCode: "africa" },
  "es": { name: "Spain", zoneCode: "eme" },
  "et": { name: "Ethiopia", zoneCode: "africa" },
  "eu": { name: "Europe", zoneCode: "eme" },
  "fi": { name: "Finland", zoneCode: "eme" },
  "fj": { name: "Fiji", zoneCode: "apac" },
  "fk": { name: "Falkland Islands (Malvinas)", zoneCode: "southam" },
  "fm": { name: "Micronesia", zoneCode: "apac" },
  "fo": { name: "Faroe Islands", zoneCode: "eme" },
  "fr": { name: "France", zoneCode: "eme" },
  "ga": { name: "Gabon", zoneCode: "africa" },
  "gb": { name: "United Kingdom", zoneCode: "eme" },
  "gd": { name: "Grenada", zoneCode: "northam" },
  "ge": { name: "Georgia", zoneCode: "apac" },
  "gf": { name: "French Guiana", zoneCode: "southam" },
  "gg": { name: "Guernsey", zoneCode: "eme" },
  "gh": { name: "Ghana", zoneCode: "africa" },
  "gi": { name: "Gibraltar", zoneCode: "eme" },
  "gl": { name: "Greenland", zoneCode: "northam" },
  "gm": { name: "Gambia", zoneCode: "africa" },
  "gn": { name: "Guinea", zoneCode: "africa" },
  "gp": { name: "Guadeloupe", zoneCode: "northam" },
  "gq": { name: "Equatorial Guinea", zoneCode: "africa" },
  "gr": { name: "Greece", zoneCode: "eme" },
  "gs": { name: "South Georgia and the South Sandwich Islands", zoneCode: "antarctica" },
  "gt": { name: "Guatemala", zoneCode: "northam" },
  "gu": { name: "Guam", zoneCode: "apac" },
  "gw": { name: "Guinea-Bissau", zoneCode: "africa" },
  "gy": { name: "Guyana", zoneCode: "southam" },
  "hk": { name: "Hong Kong", zoneCode: "apac" },
  "hm": { name: "Heard Island and McDonald Islands", zoneCode: "antarctica" },
  "hn": { name: "Honduras", zoneCode: "northam" },
  "hr": { name: "Croatia", zoneCode: "eme" },
  "ht": { name: "Haiti", zoneCode: "northam" },
  "hu": { name: "Hungary", zoneCode: "eme" },
  "id": { name: "Indonesia", zoneCode: "apac" },
  "ie": { name: "Ireland", zoneCode: "eme" },
  "il": { name: "Israel", zoneCode: "eme" },
  "im": { name: "Isle of Man", zoneCode: "eme" },
  "in": { name: "India", zoneCode: "apac" },
  "io": { name: "British Indian Ocean Territory", zoneCode: "apac" },
  "iq": { name: "Iraq", zoneCode: "apac" },
  "ir": { name: "Iran", zoneCode: "apac" },
  "is": { name: "Iceland", zoneCode: "eme" },
  "it": { name: "Italy", zoneCode: "eme" },
  "je": { name: "Jersey", zoneCode: "eme" },
  "jm": { name: "Jamaica", zoneCode: "northam" },
  "jo": { name: "Jordan", zoneCode: "apac" },
  "jp": { name: "Japan", zoneCode: "apac" },
  "ke": { name: "Kenya", zoneCode: "africa" },
  "kg": { name: "Kyrgyzstan", zoneCode: "apac" },
  "kh": { name: "Cambodia", zoneCode: "apac" },
  "ki": { name: "Kiribati", zoneCode: "apac" },
  "km": { name: "Comoros", zoneCode: "africa" },
  "kn": { name: "Saint Kitts and Nevis", zoneCode: "northam" },
  "kp": { name: "Korea", zoneCode: "apac" },
  "kr": { name: "Korea", zoneCode: "apac" },
  "kw": { name: "Kuwait", zoneCode: "apac" },
  "ky": { name: "Cayman Islands", zoneCode: "northam" },
  "kz": { name: "Kazakhstan", zoneCode: "apac" },
  "la": { name: "Lao People's Democratic Republic", zoneCode: "apac" },
  "lb": { name: "Lebanon", zoneCode: "apac" },
  "lc": { name: "Saint Lucia", zoneCode: "northam" },
  "li": { name: "Liechtenstein", zoneCode: "eme" },
  "lk": { name: "Sri Lanka", zoneCode: "apac" },
  "lr": { name: "Liberia", zoneCode: "africa" },
  "ls": { name: "Lesotho", zoneCode: "africa" },
  "lt": { name: "Lithuania", zoneCode: "eme" },
  "lu": { name: "Luxembourg", zoneCode: "eme" },
  "lv": { name: "Latvia", zoneCode: "eme" },
  "ly": { name: "Libyan Arab Jamahiriya", zoneCode: "africa" },
  "ma": { name: "Morocco", zoneCode: "africa" },
  "mc": { name: "Monaco", zoneCode: "eme" },
  "md": { name: "Moldova", zoneCode: "eme" },
  "me": { name: "Montenegro", zoneCode: "eme" },
  "mf": { name: "Saint Martin", zoneCode: "northam" },
  "mg": { name: "Madagascar", zoneCode: "africa" },
  "mh": { name: "Marshall Islands", zoneCode: "apac" },
  "mk": { name: "Macedonia", zoneCode: "eme" },
  "ml": { name: "Mali", zoneCode: "africa" },
  "mm": { name: "Myanmar", zoneCode: "apac" },
  "mn": { name: "Mongolia", zoneCode: "apac" },
  "mo": { name: "Macao", zoneCode: "apac" },
  "mp": { name: "Northern Mariana Islands", zoneCode: "apac" },
  "mq": { name: "Martinique", zoneCode: "northam" },
  "mr": { name: "Mauritania", zoneCode: "africa" },
  "ms": { name: "Montserrat", zoneCode: "northam" },
  "mt": { name: "Malta", zoneCode: "eme" },
  "mu": { name: "Mauritius", zoneCode: "africa" },
  "mv": { name: "Maldives", zoneCode: "apac" },
  "mw": { name: "Malawi", zoneCode: "africa" },
  "mx": { name: "Mexico", zoneCode: "northam" },
  "my": { name: "Malaysia", zoneCode: "apac" },
  "mz": { name: "Mozambique", zoneCode: "africa" },
  "na": { name: "Namibia", zoneCode: "africa" },
  "nc": { name: "New Caledonia", zoneCode: "apac" },
  "ne": { name: "Niger", zoneCode: "africa" },
  "nf": { name: "Norfolk Island", zoneCode: "apac" },
  "ng": { name: "Nigeria", zoneCode: "africa" },
  "ni": { name: "Nicaragua", zoneCode: "northam" },
  "nl": { name: "Netherlands", zoneCode: "eme" },
  "no": { name: "Norway", zoneCode: "eme" },
  "np": { name: "Nepal", zoneCode: "apac" },
  "nr": { name: "Nauru", zoneCode: "apac" },
  "nu": { name: "Niue", zoneCode: "apac" },
  "nz": { name: "New Zealand", zoneCode: "apac" },
  "om": { name: "Oman", zoneCode: "apac" },
  "pa": { name: "Panama", zoneCode: "northam" },
  "pe": { name: "Peru", zoneCode: "southam" },
  "pf": { name: "French Polynesia", zoneCode: "apac" },
  "pg": { name: "Papua New Guinea", zoneCode: "apac" },
  "ph": { name: "Philippines", zoneCode: "apac" },
  "pk": { name: "Pakistan", zoneCode: "apac" },
  "pl": { name: "Poland", zoneCode: "eme" },
  "pm": { name: "Saint Pierre and Miquelon", zoneCode: "northam" },
  "pn": { name: "Pitcairn", zoneCode: "apac" },
  "pr": { name: "Puerto Rico", zoneCode: "northam" },
  "ps": { name: "Palestinian Territory", zoneCode: "apac" },
  "pt": { name: "Portugal", zoneCode: "eme" },
  "pw": { name: "Palau", zoneCode: "apac" },
  "py": { name: "Paraguay", zoneCode: "southam" },
  "qa": { name: "Qatar", zoneCode: "apac" },
  "re": { name: "Reunion", zoneCode: "africa" },
  "ro": { name: "Romania", zoneCode: "eme" },
  "rs": { name: "Serbia", zoneCode: "eme" },
  "ru": { name: "Russian Federation", zoneCode: "eme" },
  "rw": { name: "Rwanda", zoneCode: "africa" },
  "sa": { name: "Saudi Arabia", zoneCode: "apac" },
  "sb": { name: "Solomon Islands", zoneCode: "apac" },
  "sc": { name: "Seychelles", zoneCode: "africa" },
  "sd": { name: "Sudan", zoneCode: "africa" },
  "se": { name: "Sweden", zoneCode: "eme" },
  "sg": { name: "Singapore", zoneCode: "apac" },
  "sh": { name: "Saint Helena", zoneCode: "africa" },
  "si": { name: "Slovenia", zoneCode: "eme" },
  "sj": { name: "Svalbard and Jan Mayen", zoneCode: "eme" },
  "sk": { name: "Slovakia", zoneCode: "eme" },
  "sl": { name: "Sierra Leone", zoneCode: "africa" },
  "sm": { name: "San Marino", zoneCode: "eme" },
  "sn": { name: "Senegal", zoneCode: "africa" },
  "so": { name: "Somalia", zoneCode: "africa" },
  "sr": { name: "Suriname", zoneCode: "southam" },
  "ss": { name: "South Sudan", zoneCode: "africa" },
  "st": { name: "Sao Tome and Principe", zoneCode: "africa" },
  "sv": { name: "El Salvador", zoneCode: "northam" },
  "sx": { name: "Sint Maarten", zoneCode: "southam" },
  "sy": { name: "Syrian Arab Republic", zoneCode: "apac" },
  "sz": { name: "Swaziland", zoneCode: "africa" },
  "tc": { name: "Turks and Caicos Islands", zoneCode: "northam" },
  "td": { name: "Chad", zoneCode: "africa" },
  "tf": { name: "French Southern Territories", zoneCode: "antarctica" },
  "tg": { name: "Togo", zoneCode: "africa" },
  "th": { name: "Thailand", zoneCode: "apac" },
  "tj": { name: "Tajikistan", zoneCode: "apac" },
  "tk": { name: "Tokelau", zoneCode: "apac" },
  "tl": { name: "Timor-Leste", zoneCode: "apac" },
  "tm": { name: "Turkmenistan", zoneCode: "apac" },
  "tn": { name: "Tunisia", zoneCode: "africa" },
  "to": { name: "Tonga", zoneCode: "apac" },
  "tr": { name: "Turkey", zoneCode: "eme" },
  "tt": { name: "Trinidad and Tobago", zoneCode: "northam" },
  "tv": { name: "Tuvalu", zoneCode: "apac" },
  "tw": { name: "Taiwan", zoneCode: "apac" },
  "tz": { name: "Tanzania", zoneCode: "africa" },
  "ua": { name: "Ukraine", zoneCode: "eme" },
  "ug": { name: "Uganda", zoneCode: "africa" },
  "um": { name: "United States Minor Outlying Islands", zoneCode: "apac" },
  "us": { name: "United States", zoneCode: "northam" },
  "uy": { name: "Uruguay", zoneCode: "southam" },
  "uz": { name: "Uzbekistan", zoneCode: "apac" },
  "va": { name: "Holy See (Vatican City State)", zoneCode: "eme" },
  "vc": { name: "Saint Vincent and the Grenadines", zoneCode: "northam" },
  "ve": { name: "Venezuela", zoneCode: "southam" },
  "vg": { name: "Virgin Islands", zoneCode: "northam" },
  "vi": { name: "Virgin Islands", zoneCode: "northam" },
  "vn": { name: "Vietnam", zoneCode: "apac" },
  "vu": { name: "Vanuatu", zoneCode: "apac" },
  "wf": { name: "Wallis and Futuna", zoneCode: "apac" },
  "ws": { name: "Samoa", zoneCode: "apac" },
  "ye": { name: "Yemen", zoneCode: "apac" },
  "yt": { name: "Mayotte", zoneCode: "africa" },
  "za": { name: "South Africa", zoneCode: "africa" },
  "zm": { name: "Zambia", zoneCode: "africa" },
  "zw": { name: "Zimbabwe", zoneCode: "africa" },
  "unknown": { name: "Unknown country", zoneCode: "other" },
} as const;

export type CountryCode = keyof typeof COUNTRIES;

export function isCountryCode(code: string | null | undefined): code is CountryCode {
  return code ? code in COUNTRIES : false;
}

export function isZoneCode(code: string | null | undefined): code is ZoneCode {
  return code ? code in ZONES : false;
}