import { useContext, useEffect, useRef, Fragment } from 'react'

import { Country, Zone } from '../components/types';
import { SettingsContext } from '../components/settings';
import { isDeepEqual } from 'remeda';
import { Flag } from './common';
import { Filter, Focus, SearchContext, Suggestion, suggestionSections } from './search-context';
import classNames from 'classnames';
import { formatISO, formatISOWithOptions } from 'date-fns/fp';

export const QueryInput = () => {
    const { query, focus, setQuery, setFocus, next, previous, onBlur } = useContext(SearchContext);

    const focusValue = { target: 'query' } as Focus;

    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => { if (isDeepEqual(focus, focusValue)) ref.current?.focus(); }, [focus]);

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.key.length === 1) {
                setFocus(focusValue);
                if (event.key === '/') event.preventDefault();
            }
        };
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, [setFocus]);

    return (
        <input
            ref={ref}
            type="search"
            placeholder="Search"
            className="search-focus-container"
            value={query}
            onChange={e => setQuery(e.target.value)}
            onFocus={(e) => setFocus(focusValue)}
            onBlur={onBlur}
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'ArrowDown') next();
                if (e.key === 'ArrowUp') previous();
                if (e.key === 'Escape') {
                    if (query === '') { setFocus(null); } else { setQuery(''); }
                }
            }}
        />
    );
};

export const Filters = () => {
    const { filters } = useContext(SearchContext);

    return (
        <ul className="filters search-focus-container">
            {filters.map((filter, index) => (
                <FilterLine
                    key={filter.type}
                    filter={filter}
                    focusValue={{ target: 'filters', index }}
                />
            ))}
        </ul >
    );
};

const FilterLine = ({ filter, focusValue }: { filter: Filter, focusValue: Focus }) => {
    const { next, previous, removeFilter, focus, setFocus, onBlur } = useContext(SearchContext);

    const ref = useRef<HTMLButtonElement>(null);
    useEffect(() => { if (isDeepEqual(focus, focusValue)) ref.current?.focus(); }, [ref, focus, focusValue]);

    return <li
        onKeyDown={e => {
            if (e.key === 'Escape') (e.target as HTMLElement).blur();
            if (e.key === 'ArrowUp') previous();
            if (e.key === 'ArrowDown') next();
        }}>
        <button
            ref={ref}
            onClick={() => removeFilter(filter)}
            onFocus={(e) => setFocus(focusValue)}
            onBlur={onBlur}
        >&times;</button>
        <FilterContent filter={filter} />
    </li>;
};

const FilterContent = ({ filter }: { filter: Filter }) => {
    switch (filter.type) {
        case 'wsdc': return "Only showing WSDC events";
        case 'hotel': return "Only showing hotel events";
        case 'videos': return "Only showing events with videos";
        case 'results': return "Only showing events with results";
        case 'hide-cancelled': return "Hiding cancelled events";
        case 'regions':
            const items = [
                ...filter.zones.map(zone => <ZoneButton key={zone.code} zone={zone} />),
                ...filter.countries.map(country => <CountryButton key={country.code} country={country} />),
            ];
            if (items.length === 0) return "No regions filters, all events hidden";
            return <>
                Only showing events in {items[0]}
                {items.length > 1 && <>
                    {items.slice(1, -1).map((item, index) => (
                        <Fragment key={index}>, {item}</Fragment>
                    ))} and {items[items.length - 1]}
                </>}
            </>;
        case 'staff':
            return <>
                Only showing events with <StaffButton name={filter.names[0]} />
                {filter.names.length > 1 && <>
                    {filter.names.slice(1, -1).map(name => <Fragment key={name}>, <StaffButton name={name} /></Fragment>)}
                    {" "}or <StaffButton name={filter.names[filter.names.length - 1]} />
                </>}
            </>;
    }
};

const StaffButton = ({ name }: { name: string }) => {
    const { setSettings } = useContext(SettingsContext);
    return <button tabIndex={-1} onClick={() => setSettings({ type: 'remove-staff', name })}>{name}</button>;
};

const ZoneButton = ({ zone }: { zone: Zone }) => {
    const { setSettings } = useContext(SettingsContext);
    return <button tabIndex={-1} onClick={() => setSettings({ type: 'set-include-zone', zone, include: false, revertToWorldIfEmpty: true })}>{zone.name}</button>;
};

const CountryButton = ({ country }: { country: Country }) => {
    const { setSettings } = useContext(SettingsContext);
    return <button tabIndex={-1} onClick={() => setSettings({ type: 'set-include-country', country, include: false, revertToWorldIfEmpty: true })}>
        <span className={`fi fi-${country.code}`} /> {country.name}
    </button>;
};

export const $Suggestions = () => {
    const { suggestions, focus } = useContext(SearchContext);

    let index = 0;

    const items = [];

    for (const section of suggestionSections) {
        if (suggestions[section].length === 0) continue;
        for (const suggestion of suggestions[section]) {
            items.push(
                <$Suggestion
                    key={suggestionKey(suggestion)}
                    suggestion={suggestion}
                    focusValue={{ target: 'suggestions', index }}
                />
            );
            index++;
        }
    }

    const show = focus !== null;
    return (
        <ul tabIndex={-1} className={classNames('suggestions', 'search-focus-container', show && 'show')}>{items}</ul>
    );
};

const $Suggestion = ({ suggestion, focusValue }: { suggestion: Suggestion, focusValue: Focus }) => {
    const { setFocus, next, previous, addSuggestion, focus, onBlur } = useContext(SearchContext);
    const ref = useRef<HTMLLIElement>(null);
    useEffect(() => { if (isDeepEqual(focus, focusValue)) ref.current?.focus(); }, [ref, focus, focusValue]);
    return (
        <li
            ref={ref} tabIndex={0} className="suggestion"
            onClick={() => addSuggestion(suggestion)}
            onFocus={(e) => setFocus(focusValue)}
            onBlur={onBlur}
            onKeyDown={e => {
                if (e.key === 'Escape') setFocus({ target: 'query' });
                if (e.key === 'ArrowUp') previous();
                if (e.key === 'ArrowDown') next();
                if (e.key === 'Enter') addSuggestion(suggestion);
            }}
        >
            <SuggestionContent suggestion={suggestion} />
        </li>
    );
};

const SuggestionContent = ({ suggestion }: { suggestion: Suggestion }) => {
    const { settings } = useContext(SettingsContext);

    switch (suggestion.type) {
        case 'year': return `Go to year ${suggestion.year}`;
        case 'staff':
            const prefix = settings.onlyWithPeople === null ? 'Only' : 'Also';
            return `${prefix} show events with 👤${suggestion.name}`;
        case 'world': return 'Show events in the whole world';
        case 'zone': return `Only show events in ${suggestion.zone.name}`;
        case 'country': return <>
            Only show events in <Flag country={suggestion.country} /> {suggestion.country.name}
        </>;
        case 'wsdc': return 'Only show WSDC events';
        case 'hotel': return 'Only show hotel events';
        case 'videos': return 'Only show events with videos';
        case 'results': return 'Only show events with results';
        case 'hide-cancelled': return 'Hide cancelled events';
        case 'event': return <>
            Go to {formatISOWithOptions({ representation: 'date' }, suggestion.event.sunday)}
            {" "}<Flag country={suggestion.event.country} /> {suggestion.event.name}
        </>;
    }
};

function suggestionKey(suggestion: Suggestion): string {
    switch (suggestion.type) {
        case 'year': return `year-${suggestion.year}`;
        case 'staff': return `staff-${suggestion.name}`;
        case 'world': return 'world';
        case 'zone': return `zone-${suggestion.zone.code}`;
        case 'country': return `country-${suggestion.country.code}`;
        case 'wsdc': return 'wsdc';
        case 'hotel': return 'hotel';
        case 'videos': return 'videos';
        case 'results': return 'results';
        case 'hide-cancelled': return 'hide-cancelled';
        case 'event': return `event-${formatISO(suggestion.event.sunday)}-${suggestion.event.name}`;
    }
}
