import { isSameDay, eachWeekOfInterval, format, formatISO, subDays, formatISOWithOptions } from "date-fns/fp";
import classNames from "classnames";
import { useContext } from "react";

import { Event, Weekend } from "./types";
import { thisSunday } from "./common";
import { EventCard } from "./event";
import { filterEvents, SettingsContext } from "./settings";

import '../styles/calendar.scss';

export const Calendar = ({ weekends, firstSunday, lastSunday }: {
    weekends: Map<number, Weekend>,
    firstSunday: Date,
    lastSunday: Date,
}) => {
    const { settings } = useContext(SettingsContext);
    const items = [];

    let collapsedWeekends: Date[] = [];
    for (const sunday of eachWeekOfInterval({ start: firstSunday, end: lastSunday })) {
        const weekend = weekends.get(sunday.getTime());
        const events = filterEvents(settings, weekend?.events ?? []);

        if (events.length > 0 || settings.emptyWeekends === 'show') {
            if (collapsedWeekends.length > 0) {
                items.push(
                    <$Gap key={formatISO(collapsedWeekends[0])} emptyWeekends={collapsedWeekends} />
                );
                collapsedWeekends = [];
            } 
            items.push(
                <$Weekend key={formatISO(sunday)} sunday={sunday} events={events} />
            );
        } else if (settings.emptyWeekends === 'collapse') {
            collapsedWeekends.push(sunday);
        }
    }
    if (collapsedWeekends.length > 0) {
        items.push(
            <$Gap key={formatISO(collapsedWeekends[0])} emptyWeekends={collapsedWeekends} />
        );
    }

    return (
        <ul className="calendar">{items}</ul>
    );
}

const $Weekend = ({ sunday, events }: { sunday: Date, events: Event[] }) => {
    const currentWeek = isSameDay(thisSunday, sunday);
    return (
        <li key={formatISO(sunday)}
            data-sunday={formatISOWithOptions({ representation: 'date' }, sunday)}
            className={classNames('weekend', currentWeek && 'current-week')}>
            <section className="dates">
                <SatSun sunday={sunday} />
            </section>
            <section className="events">
                {events.map((e, i) => (
                    <EventCard key={i} event={e} />
                ))}
            </section>
        </li>
    );
};

const $Gap = ({ emptyWeekends }: { emptyWeekends: Date[] }) => {
    const currentWeek = emptyWeekends.some(sunday => isSameDay(thisSunday, sunday));
    return (
        <li key={formatISO(emptyWeekends[0])} className={classNames('gap', currentWeek && 'current-week')}>
            <section className="message">
                {emptyWeekends.length} {emptyWeekends.length > 1 ? "weekends" : "weekend"} without events
            </section>
        </li>
    );
}

const SatSun = ({ sunday }: { sunday: Date }) => <>
    <$Date date={subDays(1, sunday)} />
    <$Date date={sunday} />
</>;

const $Date = ({ date }: { date: Date }) => <>
    <span className="date">
        <span className="day">{format('d', date)}</span>
        <span className="month">{format('MMMM', date)}</span>
        <span className="year">{format('yyyy', date)}</span>
    </span>
</>;
