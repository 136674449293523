'use client';

import useSWR from 'swr';
import { useEffect, useContext, useRef } from 'react'
import { addDays, formatISOWithOptions, nextSunday, subDays } from 'date-fns/fp';
import { pipe } from 'remeda';

import { EventModal, EventModalContextProvider } from '../components/event';
import { SettingsContext, SettingsContextProvider } from '../components/settings';
import { Calendar } from '../components/calendar';
import { DataContext, inputToData } from '../components/data';
import { TopBar } from '../components/topbar';

import '../styles/index.scss';
import { ScrollContext, ScrollContextProvider } from '../components/scroll-context';

export default function Page() {
  const { data } = useSWR('events.json', async (url) => {
    const response = await fetch(url);
    const json = await response.json();
    return inputToData(json);
  });

  return data ? <>
    <DataContext.Provider value={data}>
      <SettingsContextProvider>
        <EventModalContextProvider>
          <ScrollContextProvider>
            <TopBar />
            <Events />
            <EventModal />
          </ScrollContextProvider>
        </EventModalContextProvider>
      </SettingsContextProvider>
    </DataContext.Provider>
  </> : 'Loading...';
}

const Events = () => {
  const { weekends, firstYear, lastYear } = useContext(DataContext);
  const { settings } = useContext(SettingsContext);
  const { scrollTarget, setScrollTarget } = useContext(ScrollContext);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (scrollTarget?.target === 'sunday') {
      setScrollTarget(null);
      const sundayString = formatISOWithOptions({ representation: 'date' }, scrollTarget.sunday);
      const target = document.querySelector(`*[data-sunday="${sundayString}"]`);
      const container = ref.current;
      if (!target || !container) { return; }
      container.scrollTo({
        top: container.scrollTop + target.getBoundingClientRect().y - 100,
        behavior: 'smooth',
      });
    }
  }, [scrollTarget]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'PageDown') {
        ref.current?.scrollBy({ top: 0.9 * ref.current.clientHeight, behavior: 'smooth' });
        event.preventDefault();
      }
      if (event.key === 'PageUp') {
        ref.current?.scrollBy({ top: -0.9 * ref.current.clientHeight, behavior: 'smooth' });
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [/* once */]);

  return (
    <main ref={ref} tabIndex={-1}>
      <GoToPrevYear />
      <Calendar
        firstSunday={pipe(new Date(settings.year ?? firstYear, 0, 1), subDays(13), nextSunday)}
        lastSunday={pipe(new Date(settings.year ?? lastYear, 11, 31), addDays(6), nextSunday)}
        weekends={weekends} />
      <GoToNextYear />
    </main>
  );
};

const GoToPrevYear = () => {
  const { firstYear } = useContext(DataContext);
  const { settings, setSettings } = useContext(SettingsContext);
  const selectedYear = settings.year;

  return selectedYear === null ? null : <>
    <div className="loadmore past">
      {(selectedYear > firstYear) ? (
        <button type="button" onClick={() => setSettings({ type: 'set-year', year: selectedYear - 1 })}>
          Go to {selectedYear - 1}
        </button>
      ) : (
        <span>No events in the database before {firstYear}</span>
      )}
    </div>
  </>;
};

const GoToNextYear = () => {
  const { lastYear } = useContext(DataContext);
  const { settings, setSettings } = useContext(SettingsContext);
  const selectedYear = settings.year;

  return selectedYear === null ? null : <>
    <div className="loadmore future">
      {(selectedYear < lastYear) ? (
        <button type="button" onClick={() => setSettings({ type: 'set-year', year: selectedYear + 1 })}>
          Go to {selectedYear + 1}
        </button>
      ) : (
        <span>No events in the database after {lastYear}</span>
      )}
    </div>
  </>;
};
