import { useState, useContext, useEffect, createContext, Dispatch } from 'react'

import { Country, Data, Event, Regions, Zone } from './types';
import { Settings, SettingsAction, SettingsContext, showEvent } from './settings';
import { DataContext } from './data';
import { isDeepEqual } from 'remeda';
import { ScrollContext } from './scroll-context';

export type Suggestion =
    | { type: 'wsdc' | 'hotel' | 'videos' | 'results' | 'hide-cancelled' }
    | { type: 'staff', name: string }
    | { type: 'world' }
    | { type: 'zone', zone: Zone }
    | { type: 'country', country: Country }
    | { type: 'year', year: number }
    | { type: 'event', event: Event };

export type Filter =
    | { type: 'regions', zones: Zone[], countries: Country[] }
    | { type: 'wsdc' }
    | { type: 'hotel' }
    | { type: 'videos' }
    | { type: 'results' }
    | { type: 'hide-cancelled' }
    | { type: 'staff', names: string[] };

export const suggestionSections = ['default', 'zones', 'countries', 'people', 'events'] as const;
export type Suggestions = { [K in typeof suggestionSections[number]]: Suggestion[] };

export type Focus = null
    | { target: 'query' }
    | { target: 'filters', index: number }
    | { target: 'suggestions', index: number };

export const SearchContext = createContext<{
    query: string,
    suggestions: Suggestions,
    filters: Filter[],
    focus: Focus,
    setFocus: (focus: Focus) => void,
    setQuery: (query: string) => void,
    next: () => void,
    previous: () => void,
    addSuggestion: (suggestion: Suggestion) => void,
    removeFilter: (filter: Filter) => void,
    onBlur: (e: React.FocusEvent) => void,
}>(undefined!);

export const SearchContextProvider = ({ children }: { children: React.ReactNode }) => {
    const data = useContext(DataContext);
    const { settings, setSettings } = useContext(SettingsContext);
    const { scrollToSunday } = useContext(ScrollContext);

    const [query, setQuery] = useState("");
    const [focus, setFocusRaw] = useState<Focus>(null);

    const filters = getFilters(data.regions, settings);
    const suggestions = getSuggestions(data, settings, query);
    const focusables = getFocusValues(suggestions, filters);

    useEffect(() => {
        const corrected = correctFocus(focus, focusables);
        if (!isDeepEqual(corrected, focus)) setFocusRaw(corrected);
    }, [focus, focusables, setFocusRaw]);

    const setFocus = (newFocus: Focus) => {
        if (isDeepEqual(newFocus, focus)) return;
        setFocusRaw(newFocus);
        if (newFocus === null) document.getElementsByTagName('main')[0]?.focus();
    };

    const context = {
        query, focus, suggestions, filters,
        setQuery,
        setFocus,
        next: () => {
            if (focus === null) return;
            const index = focusables.findIndex(isDeepEqual(focus));
            setFocus(focusables[mod(index + 1, focusables.length)]);
        },
        previous: () => {
            if (focus === null) return;
            const n = numSuggestions(suggestions);
            const index = focusables.findIndex(isDeepEqual(focus));
            setFocus(focusables[mod(index - 1, focusables.length)]);
        },
        addSuggestion: (suggestion: Suggestion) => {
            addSuggestion(settings, setSettings, scrollToSunday, suggestion);
            setQuery("");
            setFocus(null);
        },
        removeFilter: (filter: Filter) => {
            removeFilter(setSettings, filter);
        },
        onBlur: (e: React.FocusEvent) => {
            if (!e.relatedTarget?.matches('.search-focus-container, .search-focus-container *')) { setFocus(null); }
        },
    };

    return (
        <SearchContext.Provider value={context}>
            {children}
        </SearchContext.Provider>
    );
};


function getSuggestions(data: Data, settings: Settings, rawQuery: string): Suggestions {
    const query = rawQuery.trim().toLowerCase();
    const queryIsBlank = query === '';

    const suggestions: Suggestions = { default: [], people: [], countries: [], zones: [], events: [] };

    if (!settings.onlyWSDC && "wsdc".includes(query)) suggestions.default.push({ type: 'wsdc' });
    if (!settings.onlyHotel && "hotel".includes(query)) suggestions.default.push({ type: 'hotel' });
    if (!settings.onlyVideos && "videos".includes(query)) suggestions.default.push({ type: 'videos' });
    if (!settings.onlyResults && "results".includes(query)) suggestions.default.push({ type: 'results' });
    if (!settings.hideCancelled && "hide cancelled".includes(query)) suggestions.default.push({ type: 'hide-cancelled' });

    if (!queryIsBlank) {
        for (const name of data.people) {
            if (settings.onlyWithPeople?.has(name)) continue;
            if (name.toLowerCase().includes(query)) {
                suggestions.people.push({ type: 'staff', name });
                if (suggestions.people.length >= 3) break;
            }
        }
    }

    if (!queryIsBlank) {
        for (const country of data.regions.countries) {
            if (settings.regionFilter.getSingleCountry()?.code === country.code) continue;
            if (country.name.toLowerCase().includes(query) || country.code.includes(query)) {
                suggestions.countries.push({ type: 'country', country });
                if (suggestions.countries.length >= 3) break;
            }
        }
    }

    if (!settings.regionFilter.includesAll()) {
        if (queryIsBlank || 'world'.includes(query)) {
            suggestions.zones.push({ type: 'world' });
        }
    }

    for (const zone of data.regions.zones) {
        if (settings.regionFilter.getSingleZone()?.code === zone.code) continue;
        if (queryIsBlank || zone.name.toLowerCase().includes(query) || zone.code.includes(query)) {
            suggestions.zones.push({ type: 'zone', zone });
        }
    }

    if (!queryIsBlank) {
        const suggestedEvents = [];
        const queryTokens = query.split(/\s+/);
        for (const event of data.events) {
            const name = event.name.toLowerCase();
            const year = event.sunday.getFullYear().toString();
            if (queryTokens.every(token => name.includes(token) || year.includes(token))) {
                suggestedEvents.push(event);
            }
        }
        suggestedEvents.sort((a, b) => b.sunday.getTime() - a.sunday.getTime());
        for (const event of suggestedEvents) {
            suggestions.events.push({ type: 'event', event });
            if (suggestions.events.length >= 3) break;
        }
    }

    return suggestions;
}

function getFilters(regions: Regions, settings: Settings): Filter[] {
    const filters: Filter[] = [];

    if (!settings.regionFilter.includesAll()) {
        const line: Filter = { type: 'regions', zones: [], countries: [] };
        filters.push(line);

        for (const zoneCode of settings.regionFilter.getFullyIncludedZones()) {
            line.zones.push(regions.zoneMap.get(zoneCode)!);
        }

        for (const countryCode of settings.regionFilter.getOutofZoneCountries()) {
            line.countries.push(regions.countryMap.get(countryCode)!);
        }
    }

    if (settings.onlyWSDC) { filters.push({ type: 'wsdc' }); }
    if (settings.onlyHotel) { filters.push({ type: 'hotel' }); }
    if (settings.onlyVideos) { filters.push({ type: 'videos' }); }
    if (settings.onlyResults) { filters.push({ type: 'results' }); }
    if (settings.hideCancelled) { filters.push({ type: 'hide-cancelled' }); }

    if (settings.onlyWithPeople?.size) {
        filters.push({ type: 'staff', names: Array.from(settings.onlyWithPeople) });
    }

    return filters;
}

function getFocusValues(suggestions: Suggestions, filters: Filter[]): Array<Exclude<Focus, null>> {
    const focusables: Array<Exclude<Focus, null>> = [{ target: 'query' }];
    for (const index in filters) { focusables.push({ target: 'filters', index: parseInt(index) }); }
    suggestionSections.flatMap(section => suggestions[section]).forEach(
        (_, index) => focusables.push({ target: 'suggestions', index })
    );
    return focusables;
}

function addSuggestion(
    settings: Settings,
    setSettings: Dispatch<SettingsAction>,
    scrollToSunday: (sunday: Date) => void,
    suggestion: Suggestion,
) {
    if (suggestion.type === 'staff') {
        setSettings({ type: 'add-staff', name: suggestion.name });
    }
    if (suggestion.type === 'country') {
        setSettings({ type: 'focus-country', country: suggestion.country });
    }
    if (suggestion.type === 'zone') {
        setSettings({ type: 'focus-zone', zone: suggestion.zone });
    }
    if (suggestion.type === 'world') {
        setSettings({ type: 'set-include-world', include: true });
    }
    if (suggestion.type === 'wsdc') {
        setSettings({ type: 'set-only-wsdc', value: true });
    }
    if (suggestion.type === 'hotel') {
        setSettings({ type: 'set-only-with-hotel', value: true });
    }
    if (suggestion.type === 'videos') {
        setSettings({ type: 'set-only-with-videos', value: true });
    }
    if (suggestion.type === 'results') {
        setSettings({ type: 'set-only-with-results', value: true });
    }
    if (suggestion.type === 'hide-cancelled') {
        setSettings({ type: 'set-hide-cancelled', value: true });
    }
    if (suggestion.type === 'event') {
        if (!showEvent(settings, suggestion.event)) {
            setSettings({ type: 'clear-filters' })
        }
        scrollToSunday(suggestion.event.sunday);
    }
}

function removeFilter(setSettings: Dispatch<SettingsAction>, filter: Filter) {
    if (filter.type === 'staff') {
        filter.names.forEach(name => setSettings({ type: 'remove-staff', name }));
    }
    if (filter.type === 'regions') {
        setSettings({ type: 'set-include-world', include: true });
    }
    if (filter.type === 'wsdc') {
        setSettings({ type: 'set-only-wsdc', value: false });
    }
    if (filter.type === 'hotel') {
        setSettings({ type: 'set-only-with-hotel', value: false });
    }
    if (filter.type === 'videos') {
        setSettings({ type: 'set-only-with-videos', value: false });
    }
    if (filter.type === 'results') {
        setSettings({ type: 'set-only-with-results', value: false });
    }
    if (filter.type === 'hide-cancelled') {
        setSettings({ type: 'set-hide-cancelled', value: false });
    }
}

function correctFocus(focus: Focus, focusValues: Exclude<Focus, null>[]): Focus {
    if (focus === null || focusValues.find(isDeepEqual(focus))) return focus;

    return focusValues.findLast((candidate) => {
        if (focus.target === 'suggestions') {
            return candidate.target === 'query' || candidate.target === 'filters'
                || candidate.target === 'suggestions' && candidate.index <= focus.index;
        }
        if (focus.target === 'filters') {
            return candidate.target === 'query'
                || candidate.target === 'filters' && candidate.index <= focus.index;
        }
        return candidate.target === 'query';
    }) ?? focus;
}

function numSuggestions(suggestions: Suggestions): number {
    return suggestionSections.reduce((sum, section) => sum + suggestions[section].length, 0);
}

function mod(i: number, n: number) { return ((i % n) + n) % n; }
